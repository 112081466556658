<template>
  <div class="glo-product__item-img">
    <swiper ref="swiperRef" :options="swiperOption" >
      <swiper-slide v-for="item in items" :key="item">
        <img :src="`/glo/product/${item}`" alt="" />
      </swiper-slide>
    </swiper>
    <div class="arr prev" @click="prevSlide()">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="25" viewBox="0 0 11 25" fill="none">
        <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M10.7986 0.244078C11.0671 0.569515 11.0671 1.09715 10.7986 1.42259L1.65977 12.5L10.7986 23.5774C11.0671 23.9028 11.0671 24.4305 10.7986 24.7559C10.5302 25.0814 10.0948 25.0814 9.82636 24.7559L0.201365 13.0893C-0.0671214 12.7638 -0.0671214 12.2362 0.201365 11.9107L9.82636 0.244078C10.0948 -0.0813592 10.5302 -0.0813592 10.7986 0.244078Z" fill="black"/>
      </svg>
    </div>
    <div class="arr next" @click="nextSlide()">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="25" viewBox="0 0 11 25" fill="none">
        <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M0.201364 0.244078C-0.0671214 0.569515 -0.0671214 1.09715 0.201364 1.42259L9.34023 12.5L0.201364 23.5774C-0.0671217 23.9028 -0.0671217 24.4305 0.201364 24.7559C0.469849 25.0814 0.90515 25.0814 1.17364 24.7559L10.7986 13.0893C11.0671 12.7638 11.0671 12.2362 10.7986 11.9107L1.17364 0.244078C0.90515 -0.0813592 0.46985 -0.0813592 0.201364 0.244078Z" fill="black"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSlider",
  // components: {
  //   ArticlesBottomList: () =>
  //     import("@/components/articles/ArticlesBottomList.vue"),
  //   ArticlesTopList: () => import("@/components/articles/ArticlesTopList.vue"),
  // },
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      swiperOption: {
        // spaceBetween: 50,
        lazy: true,
        slideTo: 1,
        loop: true,
        // spaceBetween: ,
        // initialSlide: 1,
        // centeredSlides: false,
        // responsive: true,
        breakpoints: {
          600: {
            slidesPerView: 1,
          },
          800: {
            slidesPerView: 1,
          },
          1000: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
  
  computed: {
    swiper() {
      return this.$refs?.swiperRef
    },
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev()
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext()
    },
  },
};
</script>

<style lang="scss" scoped></style>
